<template>

    <div class="c-form-input-select-container">
        <select class="c-form-input c-form-input-select" :name="name">
            <option>{{placeholder}}</option>
            <option v-for="(option, i) in options" :value="i">{{option}}</option>
        </select>
    </div>

</template>

<script>

    export default {

        props: {
            name: {
                type: String,
                required: true
            },
            options: {
                type: Object,
                required: true
            },
            placeholder: {
                type: String
            },
        }
    }

</script>

<style>

</style>