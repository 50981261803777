var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("textarea", {
    staticClass: "c-form-input c-form-input-textarea",
    attrs: { name: _vm.name, placeholder: _vm.placeholder },
    domProps: { value: _vm.value }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }