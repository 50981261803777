<template>
    <div :style="myStyle"><slot></slot></div>
</template>

<script>

    export default {

        props: ['top', 'scrollY'],

        data() {

            return {
                myStyle: {},
                originalTop: 0,
                scrollY: null
            }
        },

        mounted() {

            this.originalTop = this.$el.offsetTop;
            this.scrollY = document.documentElement.scrollTop;

            window.addEventListener('scroll', (event) => {
                this.scrollY = Math.round(window.scrollY);
            });

            this.setSticky()

        },

        watch: {

            scrollY() {

                this.setSticky()
            }
        },

        methods: {

            setSticky() {

                const rect = this.$el.getBoundingClientRect();
                const newTop = this.scrollY + +this.top - this.originalTop;

                if (newTop > 0) {

                    this.$set(this.myStyle, 'position', `fixed`);
                    this.$set(this.myStyle, 'top', `0px`);

                    document.getElementsByClassName("c-header")[0].setAttribute("style", "margin-bottom:" + rect.height + "px;");

                } else {

                    this.$delete(this.myStyle, 'position');
                    this.$delete(this.myStyle, 'top');

                    document.getElementsByClassName("c-header")[0].setAttribute("style", "margin-bottom: 0px;");
                }
            }
        }
    };

</script>

<style>

</style>
