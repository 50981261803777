var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-form-input-select-container" }, [
    _c(
      "select",
      {
        staticClass: "c-form-input c-form-input-select",
        attrs: { name: _vm.name }
      },
      [
        _c("option", [_vm._v(_vm._s(_vm.placeholder))]),
        _vm._v(" "),
        _vm._l(_vm.options, function(option, i) {
          return _c("option", { domProps: { value: i } }, [
            _vm._v(_vm._s(option))
          ])
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }