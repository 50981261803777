<template>
    <p class="c-alert" :class="(state ? 'c-alert-' + state  : null)">
        {{message}}

        <a href="#" class="c-alert-toggle" @click.prevent="close">&times;</a>
    </p>
</template>

<script>

export default {

    props: {
        state: {
            type: String
        },
        message: {
            type: String,
            required: true
        }
    },

    methods: {
        close: function (e) {
            $(e.target).parent().remove();
        }
    }
}

</script>

<style>

</style>