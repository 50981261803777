import $ from "jquery";
import Vue from 'vue';

import navmobile from './components/navmobile.vue';
import icons from './components/icons.vue';
import alert from './components/alert.vue';
import tabs from './components/tabs.vue';
import tab from './components/tab.vue';
import stickyTop from './components/stickyTop.vue';

import googlemaps from './components/gmap.vue';

import frmbutton from './components/forms/frmbutton.vue';
import frmcheckbox from './components/forms/frmcheckbox.vue';
import frmdatepicker from './components/forms/frmdatepicker.vue';
import frminput from './components/forms/frminput.vue';
import frmradio from './components/forms/frmradio.vue';
import frmselect from './components/forms/frmselect.vue';
import frmtextarea from './components/forms/frmtextarea.vue';

import Slick from 'vue-slick';

class App {

    constructor() {

        window.jQuery = $;
        window.$ = $;
    }

    init() {

        var FormBuilderSubmitted = document.getElementById("FormBuilderSubmitted");
        if (FormBuilderSubmitted) {
          window.location.hash = '#reservatie';
        }

        new Vue({
            el: '#app',
            delimiters: ['<%', '%>'],
            components: {

                // Components
                'navmobile' : navmobile,
                'icon' : icons,
                'alert' : alert,
                'tabs' : tabs,
                'tab' : tab,
                'sticky-top' : stickyTop,

                // Vendors
                'Slick' : Slick,

                // Google Maps
                'googlemaps' : googlemaps,

                // Form Elements
                'frmbutton' : frmbutton,
                'frmcheckbox' : frmcheckbox,
                'frmdatepicker' : frmdatepicker,
                'frminput' : frminput,
                'frmradio' : frmradio,
                'frmselect' : frmselect,
                'frmtextarea' : frmtextarea
            }
        });
    }
}

$(() => {

    const app = new App();

    app.init();

});