var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    staticClass: "c-form-input c-form-input-text",
    attrs: {
      type: _vm.type,
      name: _vm.name,
      min: _vm.min,
      max: _vm.max,
      placeholder: _vm.placeholder
    },
    domProps: { value: _vm.value }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }