<template>
    <svg class="c-icon" :class="'c-icon-' + id"><use :xlink:href="'/prototype/dist/sprite/sprite.svg#' + id"></use></svg>

</template>

<script>
    export default {
        props: {
            id: {
                type: String,
                required: true
            }
        }
    }
</script>

<style>

</style>

