var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-form-input-datepicker-container" }, [
    _c("input", {
      staticClass: "c-form-input c-form-input-datepicker",
      attrs: {
        type: "text",
        readonly: "readonly",
        name: _vm.name,
        placeholder: _vm.placeholder
      },
      domProps: { value: _vm.value }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }