<template>
    <button class="c-button" :type="type" :class="[(state ? 'c-button-' + state : '')]">{{label}}</button>
</template>

<script>

    export default {

        props: {
            state: {
                type: String
            },
            type: {
                default: 'submit',
                type: String
            },
            label: {
                type: String,
                required: true
            }
        }
    }

</script>

<style>

</style>