<template>

    <input class="c-form-input c-form-input-text" :type="type" :name="name" :value="value" :min="min" :max="max" :placeholder="placeholder">

</template>

<script>

    export default {

        props: {
            type: {
                default: 'text',
                type: String
            },
            name: {
                type: String,
                required: true
            },
            value: {
                type: String
            },
            placeholder: {
                type: String
            },
            min: {
                type: String
            },
            max: {
                type: String
            }
        }
    }

</script>

<style>

</style>