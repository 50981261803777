<template>

    <div class="c-tabs">

        <section class="c-section u-padding-bottom-md u-padding-bottom-xl-md">
            <div class="o-container-md o-flex o-flex-justify-content-center">
                <nav class="c-nav-menu o-inline-flex-md u-text-center">
                    <a v-for="tab in tabs" :href="tab.href" :class="{ 'is-active': tab.isActive }" @click="selectTab(tab.name)">{{ tab.name }}</a>
                </nav>
            </div>
        </section>

        <div class="c-tabs-details">
            <slot></slot>
        </div>

    </div>

</template>

<script>

    import Vue from 'vue';

    export default {

        data() {

            return {tabs: [] };
        },

        created() {

            this.tabs = this.$children;
        },

        methods: {

            selectTab(selectedTab) {

                this.tabs.forEach(tab => {

                    tab.isActive = (tab.name === selectedTab);
                });

                //console.log(selectedTab);
                window.dispatchEvent(new Event('resize'));
                // check de vue componenten, om zo een redraw te doen
            }
        }
    }

</script>