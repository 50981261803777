var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-tabs" }, [
    _c(
      "section",
      { staticClass: "c-section u-padding-bottom-md u-padding-bottom-xl-md" },
      [
        _c(
          "div",
          {
            staticClass: "o-container-md o-flex o-flex-justify-content-center"
          },
          [
            _c(
              "nav",
              { staticClass: "c-nav-menu o-inline-flex-md u-text-center" },
              _vm._l(_vm.tabs, function(tab) {
                return _c(
                  "a",
                  {
                    class: { "is-active": tab.isActive },
                    attrs: { href: tab.href },
                    on: {
                      click: function($event) {
                        _vm.selectTab(tab.name)
                      }
                    }
                  },
                  [_vm._v(_vm._s(tab.name))]
                )
              })
            )
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "c-tabs-details" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }