<template>

    <google-map :zoom="14" :center="{lat:51.030577, lng:4.099429}" :options="{styles: mapstyles, mapTypeControl: false, fullscreenControl: false}">

        <gmap-marker :key="i" v-for="(m,i) in markers" :position="m.position" :clickable="true" @click="toggleInfoWindow(m,i)"></gmap-marker>

    </google-map>

</template>

<script>

    import Vue from 'vue';
    import * as VueGoogleMaps from 'vue2-google-maps';

    Vue.use(VueGoogleMaps, {

        load: {
            key: 'AIzaSyDtRggE4tvoCvGXwLbJqsdmc6pA0rbAEcA',
            language: 'nl-BE',
            region: 'be',
        }
    });

    export default {

        components: {
            'google-map': VueGoogleMaps.Map,
            'gmap-marker' : VueGoogleMaps.Marker
        },

        data() {

            return {
                place: '',
                infoContent: '',
                infoWindowPos: null,
                infoWinOpen: false,
                currentMidx: null,
                infoOptions: {
                    pixelOffset: {
                        width: 0,
                        height: 0
                    }
                },
                markers: [
                    {
                        position: {
                            lat: 51.0303884,
                            lng: 4.0993966
                        },
                        infoText: 'Art Deco Dendermonde',
                        title: 'Art Deco Dendermonde'
                    }
                ],
                mapstyles : [
                    {
                        "featureType": "administrative",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            },
                            {
                                "hue": "#0066ff"
                            },
                            {
                                "saturation": 74
                            },
                            {
                                "lightness": 100
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            },
                            {
                                "weight": 0.6
                            },
                            {
                                "saturation": -85
                            },
                            {
                                "lightness": 61
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            },
                            {
                                "color": "#5f94ff"
                            },
                            {
                                "lightness": 26
                            },
                            {
                                "gamma": 5.86
                            }
                        ]
                    }
                ]
            }
        },

        methods: {

            toggleInfoWindow: function (marker, idx) {

                this.infoWindowPos = marker.position;
                this.infoContent = marker.infoText;

                //check if its the same marker that was selected if yes toggle
                if (this.currentMidx == idx) {
                    this.infoWinOpen = !this.infoWinOpen;
                }

                //if different marker set infowindow to open and reset current marker index
                else {
                    this.infoWinOpen = true;
                    this.currentMidx = idx;
                }
            }
        }
    }

</script>

<style>
    .vue-map-container {
        width: 100%;
        height: 350px;
        border: 4px solid #467769;
    }
</style>