<template>

    <div class="c-form-input-datepicker-container">
        <input type="text" class="c-form-input c-form-input-datepicker" readonly="readonly" :name="name" :value="value" :placeholder="placeholder">
    </div>

</template>

<script>

    export default {

        props: {
            name: {
                type: String,
                required: true
            },
            value: {
                type: String
            },
            placeholder: {
                type: String
            }
        },

        mounted(){
            $(this.$el.querySelector('input')).datepicker();
        }
    }

</script>

<style>

</style>