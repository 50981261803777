<template>

    <nav class="c-nav-mobile">

        <input type="checkbox" :id="id" class="c-nav-mobile-state" />

        <label :for="id" class="c-nav-mobile-button" @click="toggle">
            {{label}}
            <span class="c-nav-mobile-button-line"></span>
            <span class="c-nav-mobile-button-line"></span>
            <span class="c-nav-mobile-button-line"></span>
        </label>

        <ul class="c-nav-mobile-list" :class="'c-nav-mobile-list--' + orientation">
            <li v-for="item in items">
                <a :href="item.url" :class="item.class">{{item.label}}</a>
            </li>
        </ul>

    </nav>

</template>

<script>

export default {

    props: {
        id: {
            type: String,
            required: true
        },
        orientation: {
            type: String,
            required: true,
            default: 'overlay'
        },
        label: {
            type: String,
            required: false
        },
        items: {
            type: Array,
            required: true
        }
    },

    methods: {

        toggle: function (e) {
            let body = document.querySelector('.o-body');
            body.classList.toggle('is-' + this.orientation);
        }
    }
};

</script>

<style>

</style>
