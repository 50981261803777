var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "p",
    {
      staticClass: "c-alert",
      class: _vm.state ? "c-alert-" + _vm.state : null
    },
    [
      _vm._v("\n    " + _vm._s(_vm.message) + "\n\n    "),
      _c(
        "a",
        {
          staticClass: "c-alert-toggle",
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.close($event)
            }
          }
        },
        [_vm._v("×")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }