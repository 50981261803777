<template>

    <div class="c-form-input-radio-set">
        <div class="c-form-input-radio-container" v-for="(option, i) in options">
            <input type="radio" class="c-form-input c-form-input-radio" :name="name" :value="i" :id="'radio-' + i">
            <label :for="'radio-' + i">
                <i class="c-form-icon-radio"></i>
                {{option}}
            </label>
        </div>
    </div>

</template>

<script>

    export default {

        props: {
            name: {
                type: String,
                required: true
            },
            options: {
                type: Object,
                required: true
            }
        }
    }

</script>

<style>

</style>