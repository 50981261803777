var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "c-nav-mobile" }, [
    _c("input", {
      staticClass: "c-nav-mobile-state",
      attrs: { type: "checkbox", id: _vm.id }
    }),
    _vm._v(" "),
    _c(
      "label",
      {
        staticClass: "c-nav-mobile-button",
        attrs: { for: _vm.id },
        on: { click: _vm.toggle }
      },
      [
        _vm._v("\n        " + _vm._s(_vm.label) + "\n        "),
        _c("span", { staticClass: "c-nav-mobile-button-line" }),
        _vm._v(" "),
        _c("span", { staticClass: "c-nav-mobile-button-line" }),
        _vm._v(" "),
        _c("span", { staticClass: "c-nav-mobile-button-line" })
      ]
    ),
    _vm._v(" "),
    _c(
      "ul",
      {
        staticClass: "c-nav-mobile-list",
        class: "c-nav-mobile-list--" + _vm.orientation
      },
      _vm._l(_vm.items, function(item) {
        return _c("li", [
          _c("a", { class: item.class, attrs: { href: item.url } }, [
            _vm._v(_vm._s(item.label))
          ])
        ])
      })
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }